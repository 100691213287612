<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col sm="6">
              <ValidationProvider name="Note (En)" vid="note_en " rules="required">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="note_en "
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.note') }} {{ $t('globalTrans.en') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                    id="note_en "
                    v-model="formData.note_en "
                    rows="1"
                    max-rows="2"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="6">
              <ValidationProvider name="Note (Bn)" vid="note_bn " rules="required">
                <b-form-group
                    slot-scope="{ valid, errors }"
                    label-for="note_bn "
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.note') }} {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                    id="note_bn "
                    v-model="formData.note_bn "
                    rows="1"
                    max-rows="2"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Attachment" vid="inspection_attachment" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="inspection_attachment">
                <template v-slot:label>
                  {{ $t('license_management.attachment') }}  <span class="text-danger">*</span>
                </template>
                <b-form-file
                  name="inspection_attachment"
                  v-model="formData.inspection_attachment"
                  @change="onChange"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-inspection')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { inspectionStore } from '../../../api/routes'

export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      formData: {
        note_en: '',
        note_bn: '',
        inspection_attachment: ''
      }
    }
  },
  created () {
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    onChange (e) {
      const selectedFile = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.formData[e.target.name] = event.target.result
      }
      reader.readAsDataURL(selectedFile)
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const storeData = Object.assign(this.formData, { app_main_id: this.id })
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(lrcpnServiceBaseUrl, inspectionStore, storeData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('LrcpnService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-inspection')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
